import React, { useEffect, useState } from "react";
import { Button, TextField, MenuItem } from "@material-ui/core"
import { mainApi } from "../services/api";

function MyTextField(props) {
    return (
        <TextField
            variant="outlined" size="small" style={{ marginBottom: "8px" }}
            {...props}
        />
    );
}
const wgt_return_mode = [{ value: "all", label: "ทั้งหมด" }, { value: "found", label: "เกิดขึ้น" }, { value: "notfound", label: "ไม่เกิดขึ้น" }]
const wgt_cut_mode = [{ value: "all", label: "ทั้งหมด" }, { value: "found", label: "เกิดขึ้น" }, { value: "notfound", label: "ไม่เกิดขึ้น" }]
const flag_return_mode = [{ value: "all", label: "ทั้งหมด" }, { value: "N", label: "N" }, { value: "A", label: "A" }, { value: "-", label: "ไม่ระบุ" }]

export default function MainFilter(props) {
    const [master, setMaster] = useState(null)
    const [data, setData] = useState(null)
    const [hasData, setHasData] = useState(false)
    const handleSubmit = () => {
        localStorage.setItem("filter", JSON.stringify(data))
        props.handleClose()
    }
    useEffect(() => {
        mainApi.getMasterFilter(
            (res) => {
                if (res?.length > 0) {
                    var tmp = {
                        employee_1: ["all"].concat(res[0].employee_1.sort()),
                        employee_2: ["all"].concat(res[0].employee_2.sort()),
                        employee_3: ["all"].concat(res[0].employee_3.sort()),
                        truck_id: ["all"].concat(res[0].truck_id.sort()),
                        com_grade_name: ["all"].concat(res[0].com_grade_name.sort()),
                        prd_grade_name: ["all"].concat(res[0].prd_grade_name.sort()),
                    }
                    setMaster(tmp)
                }
            }, (err) => console.log(err))
        let filters = JSON.parse(localStorage.getItem("filter"))
        if (filters) {
            setData(filters)
            setHasData(true)
        }
    }, [])

    return (
        <>
            {hasData && <div className="setting-modal">
                <div className="title">Filter</div>
                <div className="flex-row-box" >
                    <div id="left-column" className="detail-column wide-column light-gray" style={{ lineHeight: "3rem" }}>
                        <div>ช่วงปีทำรายการ</div>
                        {/* <div>ช่วงวันที่ทำรายการ</div> */}
                        {/* <div>ช่วงเวลาที่ทำรายการ</div> */}
                        <div>พนักงานเครื่องชั่ง</div>
                        <div>พนักงานประเมินเกรดเบื้องต้น</div>
                        <div>พนักงานประเมินเกรด</div>
                        <div>ทะเบียนรถ</div>
                        <div>การตัดน้ำหนัก</div>
                        <div>Flag Return</div>
                        <div>การส่งคืน</div>
                        <div>Commercial Grade</div>
                        <div>Production Grade</div>
                        <div>Fraud Probability</div>
                    </div>
                    <div id="right-column" className="detail-column" style={{ width: "200px", lineHeight: "3rem" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "320px", marginLeft: "-120px" }}>
                            <div>
                                <MyTextField
                                    type="date"
                                    defaultValue={data?.date_from}
                                    onChange={(e) => setData({ ...data, date_from: e.target.value })}
                                    style={{ width: "150px" }}
                                />
                            </div>
                            -
                            <div>
                                <MyTextField
                                    type="date"
                                    defaultValue={data?.date_end}
                                    onChange={(e) => setData({ ...data, date_end: e.target.value })}
                                    style={{ width: "150px" }}
                                />
                            </div>
                        </div>
                        <div><MyTextField select fullWidth defaultValue={data?.employee_1 || "all"} onChange={(e) => setData({ ...data, employee_1: e.target.value })}>
                            {master?.employee_1.map((option, index) => {
                                if (option?.length > 0) {
                                    return <MenuItem key={index} value={option}>{option == "all" ? "ทั้งหมด" : option}</MenuItem>
                                }
                            })}
                        </MyTextField></div>
                        <div><MyTextField select fullWidth defaultValue={data?.employee_2 || "all"} onChange={(e) => setData({ ...data, employee_2: e.target.value })}>
                            {master?.employee_2.map((option, index) => {
                                if (option?.length > 0) {
                                    return <MenuItem key={index} value={option}>{option == "all" ? "ทั้งหมด" : option}</MenuItem>
                                }
                            })}
                        </MyTextField></div>
                        <div><MyTextField select fullWidth defaultValue={data?.employee_3 || "all"} onChange={(e) => setData({ ...data, employee_3: e.target.value })}>
                            {master?.employee_3.map((option, index) => {
                                if (option?.length > 0) {
                                    return <MenuItem key={index} value={option}>{option == "all" ? "ทั้งหมด" : option}</MenuItem>
                                }
                            })}
                        </MyTextField></div>
                        <div><MyTextField select fullWidth defaultValue={data?.truck_id || "all"} onChange={(e) => setData({ ...data, truck_id: e.target.value })}>
                            {master?.truck_id.map((option, index) => {
                                if (option?.length > 0) {
                                    return <MenuItem key={index} value={option}>{option == "all" ? "ทั้งหมด" : option}</MenuItem>
                                }
                            })}
                        </MyTextField></div>
                        <div><MyTextField select fullWidth defaultValue={data?.wgt_cut || "all"} onChange={(e) => setData({ ...data, wgt_cut: e.target.value })}>
                            {wgt_cut_mode.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>

                            ))}
                        </MyTextField></div>
                        <div><MyTextField select fullWidth defaultValue={data?.flag_return || "all"} onChange={(e) => setData({ ...data, flag_return: e.target.value })}>
                            {flag_return_mode.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>

                            ))}
                        </MyTextField></div>
                        <div><MyTextField select fullWidth defaultValue={data?.wgt_return || "all"} onChange={(e) => setData({ ...data, wgt_return: e.target.value })}>
                            {wgt_return_mode.map((option, index) => (
                                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>

                            ))}
                        </MyTextField></div>
                        <div><MyTextField select fullWidth defaultValue={data?.com_grade || "all"} onChange={(e) => setData({ ...data, com_grade: e.target.value })}>
                            {master?.com_grade_name.map((option, index) => {
                                if (option?.length > 0) {
                                    return <MenuItem key={index} value={option}>{option == "all" ? "ทั้งหมด" : option}</MenuItem>
                                }
                            })}
                        </MyTextField></div>
                        <div><MyTextField select fullWidth defaultValue={data?.prd_grade || "all"} onChange={(e) => setData({ ...data, prd_grade: e.target.value })}>
                            {master?.prd_grade_name.map((option, index) => {
                                if (option?.length > 0) {
                                    return <MenuItem key={index} value={option}>{option == "all" ? "ทั้งหมด" : option}</MenuItem>
                                }
                            })}
                        </MyTextField></div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <MyTextField
                                    type="number"
                                    defaultValue={data?.fraud_prob_min}
                                    onChange={(e) => setData({ ...data, fraud_prob_min: e.target.value })}
                                    style={{ width: "80px" }}
                                />
                            </div>
                            ถึง
                            <div>
                                <MyTextField
                                    type="number"
                                    defaultValue={data?.fraud_prob_max}
                                    onChange={(e) => setData({ ...data, fraud_prob_max: e.target.value })}
                                    style={{ width: "80px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-row-box" style={{ marginTop: "2rem" }}>
                    <Button variant="outlined" color="primary" onClick={props.handleClose}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </div>
            </div>}
        </>
    )
}