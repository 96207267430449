import React, { useState, useEffect } from "react";
import { Button, IconButton, Modal } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import FilterIcon from '../assets/img/icon_filter.png'
import SettingIcon from '../assets/img/icon_setting.png'
import ScoreBoard from "../components/scoreboard";
import FeedBackIcon from '../assets/img/icon_edit.png'
import DetailIcon from '../assets/img/icon_detail.png'
import TruckDetail from "../components/truck_detail";
import EmployeeDetail from "../components/employee_detail";
import ReceiptDetail from "../components/receipt_detail";
import { checkAccess, getFilter, numberWithCommas } from "../services/helper";
import MainFilter from "../components/mainfilter";
import { mainApi } from "../services/api";
import FormularSetting from "../components/formular_setting";

export default function Main() {

    const [masterData, setMasterData] = useState(null)
    const [data, setData] = useState(null)
    const [visibleFilter, setVisibleFilter] = useState(false)
    const [visibleFormular, setVisibleFormular] = useState(false)
    const [visibleReceiptDetail, setVisibleReceiptDetail] = useState(false)
    const [ReceiptId, setReceiptId] = useState(null)
    const [visibleTruckDetail, setVisibleTruckDetail] = useState(false)
    const [TruckId, setTruckId] = useState(null)
    const [visibleEmpDetail, setVisibleEmpDetail] = useState(false)
    const [EmpId, setEmpId] = useState(null)
    const [toggleReload, setToggleReload] = useState(false)

    const handleShowReceiptDetail = (id) => {
        setReceiptId(id)
        setVisibleReceiptDetail(true)
    }
    const handleCloseReceiptDetail = () => {
        setReceiptId(null)
        setVisibleReceiptDetail(false)
    }
    const handleShowTruckDetail = (id) => {
        setTruckId(id)
        setVisibleTruckDetail(true)
    }
    const handleCloseTruckDetail = () => {
        setTruckId(null)
        setVisibleTruckDetail(false)
    }
    const handleShowEmpDetail = (id) => {
        setEmpId(id)
        setVisibleEmpDetail(true)
    }
    const handleCloseEmpDetail = () => {
        setEmpId(null)
        setVisibleEmpDetail(false)
    }
    const handleShowFilter = () => {
        setVisibleFilter(true)
    }
    const handleCloseFilter = () => {
        setVisibleFilter(false)
        setToggleReload(!toggleReload)
    }
    const handleShowFormular = () => {
        setVisibleFormular(true)
    }
    const handleCloseFormular = () => {
        setVisibleFormular(false)
        setToggleReload(!toggleReload)
    }
    const columns = [
        { title: "ปี", field: "wgt_year" },
        { title: "รหัสรายการ", field: "wgt_ticket" },
        { title: "รหัสคลัง", field: "plant_code" },
        {
            title: "ทะเบียนรถ", field: "truck_id",
            render: row => <a onClick={() => handleShowTruckDetail(row.truck_id)}>{row.truck_id}</a>
        },
        {
            title: "พนักงานประเมินเกรด", field: "emp_id_3",
            render: row => <span><a onClick={() => handleShowEmpDetail(row.emp_id_3)}>{row.employee_3 || row.emp_id_3}</a></span>
        },
        { title: "ร้านค้า", field: "ret_name" },
        { title: "ตัวแทน", field: "trd_name" },
        {
            title: "ปริมาณเหล็ก", field: "wgt_out_net", type: "numeric",
            render: row => <text>{numberWithCommas(row.wgt_out_net)}</text>
        },
        {
            title: "%Fraud", field: "fraud_prob", type: "numeric",
            render: row => <text>{(row.fraud_prob * 100).toFixed(2)}%</text>
        },
        {
            title: "Action", field: "id",
            render: row =>
                <div className="flex-row-box" style={{ width: "80px", paddingLeft: "0px", paddingRight: "0px" }}>
                    <IconButton className="circle-button" onClick={(e) => handleShowReceiptDetail(row)}>
                        <img alt="Detail" src={DetailIcon} width="20px" />
                    </IconButton>
                    <IconButton
                        className="circle-button"
                        href={`https://docs.google.com/forms/d/e/1FAIpQLSdQByJFzLeGDb1HNiEIqwQDHJsTIJ8EL6xTpKCUA8y3aQqRfw/viewform?usp=pp_url&entry.906487514=${row.wgt_year}&entry.1790830550=${row.wgt_ticket}&entry.460341201=${row.plant_code}&entry.1004742362=${row.fraud_fx}&entry.1860812324=${row.fraud_prob}`}
                        target="_blank">
                        <img alt="Feedback" src={FeedBackIcon} width="20px" />
                    </IconButton>
                </div>
        },
    ];

    useEffect(() => {
        checkAccess()
        mainApi.getTotalMaster(
            (res) => { res?.length > 0 && setMasterData(res[0]) },
            (err) => { console.log(err) }
        )
    }, [])
    useEffect(() => {
        let filter = getFilter()
        mainApi.getAllReceipt({ ...filter, flag_fraud: "X" },
            (res) => {
                let fx = JSON.parse(sessionStorage.getItem("fx"))
                let tmp = res.map(elem => (
                    {
                        ...elem,
                        fraud_fx: `(${fx.ratio_outbound} * น้ำหนักชั่งเบาขึ้น-ลงผิดปกติ) + (${fx.ratio_inbound} * น้ำหนักชั่งเบานิ่งผิดปกติ) + (${fx.ratio_machine} * น้ำหนักระหว่างเครื่องชั่ง) + (${fx.ratio_same_plant} * การเข้าเฉพาะคลังใดคลังหนึ่ง) + (${fx.ratio_emp_1} * พนักงานเครื่องชั่ง) + (${fx.ratio_emp_2} * พนักงานประเมินเกรดเบื้องต้น) + (${fx.ratio_emp_3} * พนักงานประเมินเกรด)`
                    }
                ))
                setData({ data: tmp })
            },
            (err) => { console.log(err) }
        )
    }, [toggleReload])

    return (
        <div className="content" style={{ maxWidth: '100%' }}>
            <div className="flex-row-box">
                <div className="title">
                    รายการรับซื้อเศษเหล็ก
                </div>
                <div>
                    <Button
                        variant="outlined"
                        startIcon={<img src={SettingIcon} width="16px" />}
                        onClick={handleShowFormular}
                        style={{ marginRight: "5px" }}
                    >
                        สูตรการคำนวณ
                    </Button>
                    <Button
                        variant="outlined"
                        startIcon={<img src={FilterIcon} width="16px" />}
                        onClick={handleShowFilter}
                    >
                        Filter
                    </Button>
                </div>
            </div>
            {masterData && <div className="flex-row-box">
                <ScoreBoard title="จำนวนรายการ" value={masterData?.receipts} />
                <ScoreBoard title="จำนวนพนักงาน" value={masterData?.employees} />
                <ScoreBoard title="จำนวนทะเบียนรถ" value={masterData?.trucks} />
                <ScoreBoard title="จำนวนร้านค้า" value={masterData?.retails} />
                <ScoreBoard title="จำนวนตัวแทน" value={masterData?.traders} />
            </div>}
            <div>
                {data?.data?.length == 0 ? "ไม่พบข้อมูล" : <MaterialTable
                    title={false}
                    columns={columns}
                    data={data?.data}
                    options={{
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        headerStyle: { fontFamily: "Roboto, Noto Sans Thai, sans-serif", fontSize: "1rem" },
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50]
                    }}
                />}
            </div>
            <Modal open={visibleTruckDetail} onClose={handleCloseTruckDetail}><TruckDetail id={TruckId} /></Modal>
            <Modal open={visibleEmpDetail} onClose={handleCloseEmpDetail}><EmployeeDetail id={EmpId} /></Modal>
            <Modal open={visibleReceiptDetail} onClose={handleCloseReceiptDetail}><ReceiptDetail id={ReceiptId} openReceiptModal={handleShowReceiptDetail} openEmpModal={handleShowEmpDetail} /></Modal>
            <Modal open={visibleFilter} onClose={(handleCloseFilter)}><MainFilter handleClose={handleCloseFilter} /></Modal>
            <Modal open={visibleFormular} onClose={(handleCloseFormular)}><FormularSetting id={1} handleClose={handleCloseFormular} /></Modal>
        </div>
    )
}