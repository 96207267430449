import { mainApi } from "./api";

export function numberWithCommas(x) {
    if (x == undefined) return 0
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function redirect(path) {
    if (sessionStorage.getItem("access_token") == null) {
        return window.location.href = window.location.origin
    }
    return window.location.href = window.location.origin + path
}
export function checkAccess() {
    if (sessionStorage.getItem("access_token") == null) {
        return window.location.href = window.location.origin
    }
    return null
}
export function renderEmployee(emp_id, emp_name) {
    if(emp_id == null) return []

    let arr_emp_id = emp_id?.split('|')
    let arr_emp_name = emp_name?.split('|')

    let result = []
    for(let i=0; i<arr_emp_id.length; i++){
        result.push({emp_id: arr_emp_id[i], emp_name: arr_emp_name[i]})
    }
    return result
}
export function getFilter() {
    let filter = JSON.parse(localStorage.getItem("filter"))
    var payload = {}
    if (!filter || (filter?.date_from == undefined && filter?.date_end == undefined)) {
        mainApi.getSetting({}, (res) => {
            if (res?.length > 0) {
                let temp = {
                    date_from: res[0].date_from,
                    date_end: res[0].date_end,
                    fraud_prob_min: 30,
                    fraud_prob_max: 100
                }
                localStorage.setItem("filter", JSON.stringify(temp))
                return {
                    ddate: { "$gte": parseInt(temp.date_from.replaceAll("-", "")), "$lte": parseInt(temp.date_end.replaceAll("-", "")) },
                    fraud_prob: { "$gte": (temp.fraud_prob_min || 0) / 100, "$lte": (temp.fraud_prob_max || 100) / 100 }
                }
            }
        }, (err) => { console.log(err) })
    }
    if (filter?.date_from && filter?.date_end) {
        payload["ddate"] = {
            "$gte": parseInt(filter?.date_from.replaceAll("-", "")),
            "$lte": parseInt(filter?.date_end.replaceAll("-", ""))
        }
    } else if (filter?.date_from) {
        payload["ddate"] = {
            "$gte": parseInt(filter?.date_from.replaceAll("-", ""))
        }
    } else if (filter?.date_end) {
        payload["ddate"] = {
            "$lte": parseInt(filter?.date_end.replaceAll("-", ""))
        }
    }
    if (filter?.employee_1 && filter.employee_1 != "all")
        payload["employee_1"] = filter.employee_1
    if (filter?.employee_2 && filter.employee_2 != "all")
        payload["employee_2"] = filter.employee_2
    if (filter?.employee_3 && filter.employee_3 != "all")
        payload["employee_3"] = filter.employee_3
    if (filter?.truck_id && filter.truck_id != "all")
        payload["truck_id"] = filter.truck_id
    if (filter?.wgt_cut && filter.wgt_cut != "all") {
        payload["wgt_cut_total"] = filter.wgt_cut == "found" ? { "$gt": 0 } : 0
    }
    if (filter?.flag_return && filter.flag_return != "all")
        payload["flag_return"] = filter.flag_return == "-" ? null : filter.flag_return
    if (filter?.wgt_return && filter.wgt_return != "all") {
        payload["wgt_return"] = filter.wgt_return == "found" ? { "$gt": 0 } : 0
    }
    if (filter?.com_grade && filter.com_grade != "all") payload["com_grade_name"] = filter.com_grade
    if (filter?.prd_grade && filter.prd_grade != "all") payload["prd_grade_name"] = filter.prd_grade
    if (filter?.fraud_prob_min || filter?.fraud_prob_max) {
        payload["fraud_prob"] = { "$gte": (filter?.fraud_prob_min || 0) / 100, "$lte": (filter?.fraud_prob_max || 100) / 100 }
    }
    return payload
}