import './App.css';
import NavBar from './components/nav_bar';
import SideNavBar from './components/side_nav_bar';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Main from './pages/main';
import TruckEmp from './pages/truck_emp';
import TruckGroup from './pages/truck_group';
import FraudHistory from './pages/fraud_history';
import SignIn from './pages/signin';
import EmpEmp from './pages/emp_emp';

function App() {
  return (
    <div className="App">
      {window.location.pathname != "/" && <NavBar />}
      {window.location.pathname != "/" && <SideNavBar />}
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <SignIn />
          </Route>
          <Route exact path="/home">
            <Main />
          </Route>
          <Route path="/graph/truck-emp">
            <TruckEmp />
          </Route>
          <Route path="/graph/emp-emp">
            <EmpEmp />
          </Route>
          <Route path="/graph/truck-group">
            <TruckGroup />
          </Route>
          <Route exact path="/history">
            <FraudHistory />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
