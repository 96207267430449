import React, { useEffect, useState } from "react";
import { AppBar, Menu, MenuItem, Tooltip, IconButton, Tab, Tabs } from "@material-ui/core";
import AccountIcon from "../assets/img/icon_account.png";
import { redirect } from "../services/helper";
import NavBarItem from "./nav_bar_item";

const masterMenu = [
    { title: "Home", path: "/home" },
    { title: "Graph", path: "/graph/truck-emp" },
    { title: "History", path: "/history" },
]
export default function NavBar() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSignOut = () => {
        sessionStorage.removeItem("access_token")
        redirect("/")
    }
    useEffect(() => {

    }, [])
    return (
        <AppBar position="fixed">
            <div className="app-bar flex-row-box">
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div className="app-name" onClick={() => redirect("/home")}>
                        FRAUD DETECTION
                    </div>
                    {masterMenu.map((item) => (
                        <NavBarItem title={item.title} path={item.path}/>
                    ))}
                </div>
                <div style={{ paddingRight: "20px" }}>
                    <Tooltip title="My Account">
                        <IconButton onClick={handleOpen} style={{ padding: 0 }}>
                            <img alt="Account" src={AccountIcon} width="20px" />
                        </IconButton>
                    </Tooltip>
                </div>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 20,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem style={{ fontSize: "0.8rem" }} onClick={handleSignOut}>
                        SIGN OUT
                    </MenuItem>
                </Menu>
            </div>
        </AppBar>
    )
}