import React, { useState } from "react";
import { Button, TextField, InputAdornment, IconButton, Snackbar } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import MuiAlert from '@material-ui/lab/Alert';
import SYSLogo from "../assets/img/logo_sys.png"
import { authApi } from "../services/api";
import { redirect } from "../services/helper";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SignIn() {
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const [openFailed, setOpenFailed] = useState(false);

    const handleSignIn = () => {
        var username = document.getElementById("username").value;
        var password = document.getElementById("password").value;
        authApi.login(
            { username: username, password: password },
            (res) => {
                redirect("/home")
            },
            (err) => {
                console.log(err)
                setOpenFailed(true)
            })
    }
    return (
        <div className="log-in">
            <img alt="SYS-Logo" src={SYSLogo} width="300px" style={{ marginBottom: "1rem" }} />
            <div className="app-name">
                FRAUD DETECTION
            </div>
            <div style={{ width: "400px", marginTop: "2rem", marginBottom: "2rem" }}>
                <TextField
                    id="username"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    label="Username"
                    autoFocus
                />
                <TextField
                    id="password"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleSignIn}
            >
                Sign In
            </Button>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={openFailed}
                autoHideDuration={3000}
                onClose={() => setOpenFailed(false)}>
                <Alert onClose={() => setOpenFailed(false)} severity="error">
                    ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง
                </Alert>
            </Snackbar>
        </div>
    )
}