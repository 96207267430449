import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { mainApi } from "../services/api";
import { Divider } from "@material-ui/core"

const weightChartOption = (title, data, xdata) => {
    var opt = {
        chart: {
            width: 780,
            height: 180,
            zoomType: 'x'
        },
        title: {
            text: ""
        },
        xAxis: {
            categories: xdata,
            type: 'datetime',
            title: {
                enabled: false,
                text: 'Date'
            },
            labels: {
                formatter: function () {
                    return Highcharts.dateFormat('%b, %e %Y', this.value);
                }
            }
        },
        yAxis: {
            min: 0,
            title: {
                enabled: false,
                text: 'Weight (Kg)',
                align: 'middle'
            },
            labels: {
                overflow: 'justify'
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                }
            },
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]
                },
                marker: {
                    radius: 1
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                threshold: null
            }
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            name: title,
            data: data,
            // zones: [{
            //     value: 10000,
            //     color: '#FF0000'
            // }, {
            //     color: '#7cb5ec'
            // }]
        }]
    }
    return opt
}
const pieChartOption = (title, data) => {
    var opt = {
        chart: {
            type: 'pie',
            width: 380,
            height: 200
        },
        title: {
            text: ""
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.2f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                },
            }
        },
        series: [{
            name: title,
            data: data
        }]
    }
    return opt
}
export default function TruckDetail(props) {
    const [data, setData] = useState(null)

    useEffect(() => {
        mainApi.getTruckDetail(props.id,
            (res) => {
                if (res.length > 0) {
                    var tmp = {
                        truck_id: res[0].info[0].truck_id,
                        truck_type: res[0].info[0].truck_type,
                        truck_name: res[0].info[0].truck_name,
                        date_list: res[0].info[0].date_list?.map(elem => new Date(elem).getTime()),
                        wgt_1_list: res[0].info[0].wgt_1_list,
                        wgt_2_list: res[0].info[0].wgt_2_list,
                        wgt_diff_1_list: res[0].info[0].wgt_diff_1_list,
                        wgt_diff_2_list: res[0].info[0].wgt_diff_2_list,
                        wgt_net_list: res[0].info[0].wgt_net_list,
                        plants: res[0].plants.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        employees_1: res[0].employees_1?.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        employees_2: res[0].employees_2?.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        employees_3: res[0].employees_3?.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        retails: res[0].retails.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        traders: res[0].traders.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        com_grades: res[0].com_grades?.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        total_suspected: res[0].info[0].total_suspected,
                        total_fraud: res[0].info[0].total_fraud,
                    }
                    setData(tmp)
                }
            },
            (err) => { console.log(err) }
        )
        // setData(mock)
    }, [])

    return (
        <>
            {data && <div className="detail-modal">
                <div className="title">รถหมายเลขทะเบียน {data?.truck_id}</div>
                <div className="section-title">ข้อมูลทั่วไป</div>
                <div className="flex-row-box" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column light-gray">
                        <div>ทะเบียนรถ</div>
                        <div>รหัสประเภทรถ</div>
                        <div>ประเภทรถ</div>
                    </div>
                    <div id="right-column" className="detail-column">
                        <div>{data?.truck_id || "-"}</div>
                        <div>{data?.truck_type || "-"}</div>
                        <div>{data?.truck_name || "-"}</div>
                    </div>
                </div>
                <Divider style={{ width: "100%", margin: "20px 0px 20px 0px" }} />
                <div className="section-title">ภาพรวม</div>
                <div className="flex-row-box light-gray" style={{ justifyContent: "start" }}>
                    <div className="detail-column wide-column">
                        <div>สัดส่วนการมาทำรายการที่แต่ละคลัง</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Plant", data?.plants)}
                        />
                    </div>
                    <div id="right-column" className="detail-column wide-column">
                        <div>สัดส่วนการพบพนักงานเครื่องชั่ง</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Employee1", data?.employees_1)}
                        />
                    </div>
                </div>
                <div className="flex-row-box light-gray" style={{ justifyContent: "start" }}>
                    <div className="detail-column wide-column">
                        <div>สัดส่วนการรับเหล็กจากแต่ละร้านค้า</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Plant", data?.retails)}
                        />
                    </div>
                    <div id="right-column" className="detail-column wide-column">
                        <div>สัดส่วนการพบพนักงานประเมินเกรดเบื้องต้น</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Employee2", data?.employees_2)}
                        />
                    </div>
                </div>
                <div className="flex-row-box light-gray" style={{ justifyContent: "start" }}>
                    <div className="detail-column wide-column">
                        <div>สัดส่วนการรับเหล็กจากแต่ละตัวแทน</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Trader", data?.traders)}
                        />
                    </div>
                    <div id="right-column" className="detail-column wide-column">
                        <div>สัดส่วนการพบพนักงานประเมินเกรด</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Employee3", data?.employees_3)}
                        />
                    </div>
                </div>
                <div className="flex-row-box light-gray" style={{ justifyContent: "start" }}>
                    <div className="detail-column wide-column">
                        <div>สัดส่วนการประเมินเกรดการค้า</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Com Grade", data?.com_grades)}
                        />
                    </div>
                </div>
                <Divider style={{ width: "100%", margin: "20px 0px 20px 0px" }} />
                <div className="detail-column full-column light-gray">
                    <div style={{ padding: "10px 0px" }}>กราฟแสดงน้ำหนักชั่งหนัก</div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={weightChartOption("Weight", data?.wgt_1_list, data?.date_list)}
                    />
                    <div style={{ padding: "10px 0px" }}>กราฟแสดงน้ำหนักชั่งเบา</div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={weightChartOption("Weight", data?.wgt_2_list, data?.date_list)}
                    />
                    <div style={{ padding: "10px 0px" }}>กราฟแสดงน้ำหนักสุทธิ</div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={weightChartOption("Weight", data?.wgt_net_list, data?.date_list)}
                    />
                    <div style={{ padding: "10px 0px" }}>กราฟแสดงความต่างของน้ำหนักระหว่างเครื่องชั่งขาเข้า</div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={weightChartOption("Weight", data?.wgt_diff_1_list, data?.date_list)}
                    />
                    <div style={{ padding: "10px 0px" }}>กราฟแสดงความต่างของน้ำหนักระหว่างเครื่องชั่งขาออก</div>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={weightChartOption("Weight", data?.wgt_diff_2_list, data?.date_list)}
                    />
                </div>
                <Divider style={{ width: "100%", margin: "20px 0px 20px 0px" }} />
                <div className="section-title">ข้อมูลการ Fraud</div>
                <div className="flex-row-box" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column light-gray">
                        <div>จำนวนครั้งต้องสงสัย Fraud</div>
                        <div>จำนวนครั้ง Fraud</div>
                    </div>
                    <div id="right-column" className="detail-column">
                        <div>{data?.total_suspected}</div>
                        <div>{data?.total_fraud}</div>
                    </div>
                </div>
            </div>}
        </>
    )
}