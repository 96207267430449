import React, { useState, useEffect } from "react";
import { Button, IconButton, Modal } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import FilterIcon from '../assets/img/icon_filter.png'
import DetailIcon from '../assets/img/icon_detail.png'
import TruckDetail from "../components/truck_detail";
import EmployeeDetail from "../components/employee_detail";
import ReceiptDetail from "../components/receipt_detail";
import { checkAccess, getFilter, numberWithCommas } from "../services/helper";
import MainFilter from "../components/mainfilter";
import { mainApi } from "../services/api";

export default function FraudHistory() {
    const [data, setData] = useState(null)
    const [visibleFilter, setVisibleFilter] = useState(false)
    const [visibleReceiptDetail, setVisibleReceiptDetail] = useState(false)
    const [ReceiptId, setReceiptId] = useState(null)
    const [visibleTruckDetail, setVisibleTruckDetail] = useState(false)
    const [TruckId, setTruckId] = useState(null)
    const [visibleEmpDetail, setVisibleEmpDetail] = useState(false)
    const [EmpId, setEmpId] = useState(null)
    const [toggleReload, setToggleReload] = useState(false)

    const handleShowReceiptDetail = (id) => {
        setReceiptId(id)
        setVisibleReceiptDetail(true)
    }
    const handleCloseReceiptDetail = () => {
        setReceiptId(null)
        setVisibleReceiptDetail(false)
    }
    const handleShowTruckDetail = (id) => {
        setTruckId(id)
        setVisibleTruckDetail(true)
    }
    const handleCloseTruckDetail = () => {
        setTruckId(null)
        setVisibleTruckDetail(false)
    }
    const handleShowEmpDetail = (id) => {
        setEmpId(id)
        setVisibleEmpDetail(true)
    }
    const handleCloseEmpDetail = () => {
        setEmpId(null)
        setVisibleEmpDetail(false)
    }
    const handleShowFilter = () => {
        setVisibleFilter(true)
    }
    const handleCloseFilter = () => {
        setVisibleFilter(false)
        setToggleReload(!toggleReload)
    }
    const columns = [
        { title: "ปี", field: "wgt_year" },
        { title: "รหัสรายการ", field: "wgt_ticket" },
        { title: "รหัสคลัง", field: "plant_code" },
        {
            title: "ทะเบียนรถ", field: "truck_id",
            render: row => <a onClick={() => handleShowTruckDetail(row.truck_id)}>{row.truck_id}</a>
        },
        {
            title: "พนักงานประเมินเกรด", field: "emp_id_3",
            render: row => <span><a onClick={() => handleShowEmpDetail(row.emp_id_3)}>{row.employee_3 || row.emp_id_3}</a></span>
        },
        { title: "ร้านค้า", field: "ret_name" },
        { title: "ตัวแทน", field: "trd_name" },
        {
            title: "ปริมาณเหล็ก", field: "wgt_out_net", type: "numeric",
            render: row => <text>{numberWithCommas(row.wgt_out_net)}</text>
        },
        {
            title: "สถานะ", field: "flag_fraud",
            render: row => <text style={{ color: row.flag_fraud == "Y" ? "#B3261E" : "#378805" }}>{row.flag_fraud == "Y" ? "Fraud" : "Not Fraud"}</text>
        },
        {
            title: "Action", field: "id",
            render: row =>
                <div className="flex-row-box" style={{ width: "40px", paddingLeft: "0px", paddingRight: "0px" }}>
                    <IconButton className="circle-button" onClick={(e) => handleShowReceiptDetail(row)}>
                        <img alt="Detail" src={DetailIcon} width="20px" />
                    </IconButton>
                </div>
        },
    ];

    useEffect(() => {
        checkAccess()
        let filter = getFilter()
        var payload = { flag_fraud: { "$regex": "Y|N" } }
        mainApi.getAllReceipt(payload,
            (res) => { setData({ data: res }) },
            (err) => { console.log(err) }
        )
    }, [toggleReload])

    return (
        <div className="content" style={{ maxWidth: '100%' }}>
            <div className="flex-row-box" style={{ marginBottom: "1rem" }}>
                <div className="title">
                    ประวัติรายการรับซื้อเศษเหล็ก
                </div>
                {/* <Button
                    variant="outlined"
                    startIcon={<img src={FilterIcon} width="16px" />}
                    onClick={handleShowFilter}>
                    Filter
                </Button> */}
            </div>
            <div>
                <MaterialTable
                    title={false}
                    columns={columns}
                    data={data?.data}
                    options={{
                        search: false,
                        showTitle: false,
                        toolbar: false,
                        headerStyle: { fontFamily: "Roboto, Noto Sans Thai, sans-serif", fontSize: "1rem" },
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50]
                    }}
                />
            </div>
            <Modal open={visibleTruckDetail} onClose={handleCloseTruckDetail}><TruckDetail id={TruckId} /></Modal>
            <Modal open={visibleEmpDetail} onClose={handleCloseEmpDetail}><EmployeeDetail id={EmpId} /></Modal>
            <Modal open={visibleReceiptDetail} onClose={handleCloseReceiptDetail}><ReceiptDetail id={ReceiptId} openEmpModal={handleShowEmpDetail} /></Modal>
            <Modal open={visibleFilter} onClose={(handleCloseFilter)}><MainFilter handleClose={handleCloseFilter} /></Modal>
        </div>
    )
}