import React, { useState, useEffect } from "react";
import { Button, Modal } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import SettingIcon from '../assets/img/icon_setting.png'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import GraphSetting from '../components/graph_setting'
import { graphApi, mainApi } from "../services/api";
import { checkAccess } from "../services/helper";
import TruckDetail from "../components/truck_detail";

require("highcharts/modules/networkgraph")(Highcharts);

export default function TruckGroup() {
    const [data, setData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [visibleTruckDetail, setVisibleTruckDetail] = useState(false)
    const [TruckId, setTruckId] = useState(null)
    const [toggleReload, setToggleReload] = useState(false)

    const columns = [
        {
            title: "ทะเบียนรถ", field: "truck_1",
            render: row => <a onClick={() => handleShowTruckDetail(row.truck_1)}>{row.truck_1}</a>
        },
        { title: "จำนวนครั้งที่ทำรายการ", field: "truck_1_total" },
        {
            title: "ทะเบียนรถ", field: "truck_2",
            render: row => <a onClick={() => handleShowTruckDetail(row.truck_2)}>{row.truck_2}</a>
        },
        { title: "จำนวนครั้งที่ทำรายการ", field: "truck_2_total" },
        { title: "จำนวนครั้งที่พบกัน", field: "freq" },
        {
            title: "สัดส่วนการพบกัน", field: "rate",
            render: row => <text>{parseFloat(row.rate).toFixed(2)}</text>
        },
    ];
    const networkOption = (title, data) => {
        let temp = data.map(elem => ({ from: elem.truck_1, to: elem.truck_2 }))
        let truck_1_nodes = data.map(elem => ({ id: elem.truck_1, color: "orange" }))
        let truck_2_nodes = data.map(elem => ({ id: elem.truck_2, color: "orange" }))

        var option = {
            chart: {
                type: 'networkgraph',
                height: 450
            },
            title: {
                text: ""
            },
            plotOptions: {
                networkgraph: {
                    layoutAlgorithm: {
                        enableSimulation: true,
                        linkLength: 20
                    }
                }
            },
            series: [{
                dataLabels: {
                    enabled: true,
                    linkFormat: ''
                },
                marker: {
                    radius: 25
                },
                data: temp,
                nodes: truck_1_nodes.concat(truck_2_nodes)
            }]
        }
        return option
    }
    const handleClose = () => {
        setVisible(false)
        setToggleReload(!toggleReload)
    }
    const handleShowTruckDetail = (id) => {
        setTruckId(id)
        setVisibleTruckDetail(true)
    }
    const handleCloseTruckDetail = () => {
        setTruckId(null)
        setVisibleTruckDetail(false)
    }
    useEffect(() => {
        checkAccess()
        var payload = {}
        mainApi.getSetting({}, (res) => {
            if (res?.length > 0) {
                payload = { rate: { "$gte": res[0].ts_truck_group / 100 } }
            }
            graphApi.getTruckGroup(payload,
                (res) => { setData(res) },
                (err) => { console.log(err) }
            )
        }, (err) => { console.log(err) })
    }, [toggleReload])

    return (
        <>
            {data && <div className="content" style={{ maxWidth: '100%' }}>
                <div className="flex-row-box">
                    <div className="title">
                        กราฟแสดงความสัมพันธ์ระหว่างกลุ่มรถขนส่ง
                    </div>
                    <Button
                        variant="outlined"
                        onClick={() => setVisible(true)}
                        startIcon={<img alt="Setting" src={SettingIcon} width="16px" />}
                    >ตั้งค่า</Button>
                </div>
                <div className="graph-box">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={networkOption("Graph1", data)}
                    />
                </div>
                <div className="flex-row-box" style={{ marginBottom: "15px" }}>
                    <div className="title">
                        รายการกลุ่มรถขนส่ง
                    </div>
                </div>
                <div>
                    <MaterialTable
                        title={false}
                        columns={columns}
                        data={data}
                        options={{
                            search: false,
                            showTitle: false,
                            toolbar: false,
                            headerStyle: { fontFamily: "Roboto, Noto Sans Thai, sans-serif", fontSize: "1rem" },
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50]
                        }}
                    />
                </div>
                <Modal open={visible} onClose={handleClose}><GraphSetting handleClose={handleClose} /></Modal>
                <Modal open={visibleTruckDetail} onClose={handleCloseTruckDetail}><TruckDetail id={TruckId} /></Modal>
            </div>}
        </>
    )
}