import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core"
import { graphApi } from "../services/api";

function MyTextField(props) {
    return (
        <TextField
            required
            variant="outlined"
            size="small"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 1 } }}
            style={{ marginBottom: "8px", width: "90px" }}
            {...props}
        />
    );
}

export default function FormularSetting(props) {
    const [data, setData] = useState(null)
    const [hasData, setHasData] = useState(false)

    const handleSubmit = () => {
        var payload = {
            ratio_outbound: data?.ratio_outbound ? parseFloat(data.ratio_outbound) : 0,
            ratio_inbound: data?.ratio_inbound ? parseFloat(data.ratio_inbound) : 0,
            ratio_machine: data?.ratio_machine ? parseFloat(data.ratio_machine) : 0,
            ratio_same_plant: data?.ratio_same_plant ? parseFloat(data.ratio_same_plant) : 0,
            ratio_emp_1: data?.ratio_emp_1 ? parseFloat(data.ratio_emp_1) : 0,
            ratio_emp_2: data?.ratio_emp_2 ? parseFloat(data.ratio_emp_2) : 0,
            ratio_emp_3: data?.ratio_emp_3 ? parseFloat(data.ratio_emp_3) : 0,
        }
        graphApi.updateFormular(data?._id, payload,
            (res) => {
                sessionStorage.setItem("fx", JSON.stringify(payload))
                props.handleClose()
            }, (err) => { console.log(err) }
        )
    }
    useEffect(() => {
        graphApi.getFormular({},
            (res) => {
                setData(res)
                sessionStorage.setItem("fx", JSON.stringify(res))
                setHasData(true)
            }, (err) => console.log(err)
        )
    }, [])

    return (
        <>
            {hasData && <div className="setting-modal" style={{ width: "500px" }}>
                <div className="title">สูตรการคำนวณ Fraud Probability</div>
                <div className="flex-row-box" >
                    <div id="left-column" className="detail-column light-gray" style={{ width: "400px", lineHeight: "3rem" }}>
                        <div>สัดส่วนคะแนนน้ำหนักชั่งเบาขึ้น-ลงผิดปกติ</div>
                        <div>สัดส่วนคะแนนน้ำหนักชั่งเบานิ่งผิดปกติ</div>
                        <div>สัดส่วนคะแนนน้ำหนักระหว่างเครื่องชั่ง</div>
                        <div>สัดส่วนคะแนนการเข้าเฉพาะคลังใดคลังหนึ่ง</div>
                        <div>สัดส่วนคะแนนพนักงานเครื่องชั่ง</div>
                        <div>สัดส่วนคะแนนพนักงานประเมินเกรดเบื้องต้น</div>
                        <div>สัดส่วนคะแนนพนักงานประเมินเกรด</div>
                    </div>
                    <div id="right-column" className="detail-column" style={{ width: "90px", lineHeight: "3rem" }}>
                        <div>
                            <MyTextField
                                defaultValue={data?.ratio_outbound}
                                onChange={(e) => setData({ ...data, ratio_outbound: e.target.value })}
                            />
                        </div>
                        <div>
                            <MyTextField
                                defaultValue={data?.ratio_inbound}
                                onChange={(e) => setData({ ...data, ratio_inbound: e.target.value })}
                            />
                        </div>
                        <div>
                            <MyTextField
                                defaultValue={data?.ratio_machine}
                                onChange={(e) => setData({ ...data, ratio_machine: e.target.value })}
                            />
                        </div>
                        <div>
                            <MyTextField
                                defaultValue={data?.ratio_same_plant}
                                onChange={(e) => setData({ ...data, ratio_same_plant: e.target.value })}
                            />
                        </div>

                        <div>
                            <MyTextField
                                defaultValue={data?.ratio_emp_1}
                                onChange={(e) => setData({ ...data, ratio_emp_1: e.target.value })}
                            />
                        </div>
                        <div>
                            <MyTextField
                                defaultValue={data?.ratio_emp_2}
                                onChange={(e) => setData({ ...data, ratio_emp_2: e.target.value })}
                            />
                        </div>
                        <div>
                            <MyTextField
                                defaultValue={data?.ratio_emp_3}
                                onChange={(e) => setData({ ...data, ratio_emp_3: e.target.value })}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex-row-box" style={{ marginTop: "2rem" }}>
                    <Button variant="outlined" color="primary" onClick={props.handleClose}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </div>
            </div>}
        </>
    )
}