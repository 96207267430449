import React, { useState, useEffect } from "react";
import { Button, MenuItem, Modal, TextField } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import SettingIcon from '../assets/img/icon_setting.png'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import GraphSetting from '../components/graph_setting'
import { graphApi, mainApi } from "../services/api";
import { checkAccess } from "../services/helper";
import EmployeeDetail from "../components/employee_detail";

require("highcharts/modules/networkgraph")(Highcharts);

function MyTextField(props) {
    return (
        <TextField
            variant="outlined" size="small" style={{ width: "250px", textAlign: "left" }}
            {...props}
        />
    );
}
const DEFAULT_THRESHOLD = 0.5

export default function EmpEmp() {
    const [data, setData] = useState(null)
    const [mdata, setMdata] = useState(null)
    const [masterEmp, setMasterEmp] = useState(null)
    const [threshold, setThreshold] = useState(DEFAULT_THRESHOLD)
    const [visible, setVisible] = useState(false)
    const [visibleEmpDetail, setVisibleEmpDetail] = useState(false)
    const [EmpId, setEmpId] = useState(null)
    const [empLevel, setEmpLevel] = useState(12)
    const [toggleReload, setToggleReload] = useState(false)

    const columns = [
        {
            title: "พนักงาน", field: "e1",
            render: row => <a onClick={() => handleShowEmpDetail(row.e1)}>{masterEmp[row.e1].firstname} {masterEmp[row.e1].lastname}</a>
        },
        { title: "จำนวนครั้งที่ทำรายการ", field: "e1_total" },
        {
            title: "พนักงาน", field: "e2",
            render: row => <a onClick={() => handleShowEmpDetail(row.e2)}>{masterEmp[row.e2].firstname} {masterEmp[row.e2].lastname}</a>
        },
        { title: "จำนวนครั้งที่ทำรายการ", field: "e2_total" },
        { title: "จำนวนครั้งที่พบกัน", field: "freq" },
        {
            title: "สัดส่วนการพบกัน", field: "rate",
            render: row => <text>{parseFloat(row.rate).toFixed(2)}</text>
        },
    ];
    const networkOption = (title, data) => {
        const node_color = {
            12: ["#FFCD01", "#FC94AF"],
            13: ["#FFCD01", "#59BFFF"],
            23: ["#FC94AF", "#59BFFF"],
        }
        let temp = {}
        let emp1_nodes = {}
        let emp2_nodes = {}

        let prefix_1 = "P" + empLevel.toString()[0]
        let prefix_2 = "P" + empLevel.toString()[1]

        temp = data.map(elem => ({ from: prefix_1 + elem.e1_display, to: prefix_2 + elem.e2_display }))
        emp1_nodes = data.map(elem => ({ id: prefix_1 + elem.e1_display, color: node_color[empLevel][0], marker: { radius: 25 } }))
        emp2_nodes = data.map(elem => ({ id: prefix_2 + elem.e2_display, color: node_color[empLevel][1], marker: { radius: 25 } }))

        var option = {
            chart: {
                type: 'networkgraph',
                height: 450
            },
            title: {
                text: ""
            },
            plotOptions: {
                networkgraph: {
                    layoutAlgorithm: {
                        enableSimulation: true,
                        linkLength: 20
                    }
                }
            },
            series: [{
                dataLabels: {
                    enabled: true,
                    linkFormat: ''
                },
                marker: {
                    radius: 18
                },
                data: temp,
                nodes: emp1_nodes.concat(emp2_nodes)
            }]
        }
        return option
    }
    const handleClose = () => {
        setVisible(false)
        setToggleReload(!toggleReload)
    }
    const handleShowEmpDetail = (id) => {
        setEmpId(id)
        setVisibleEmpDetail(true)
    }
    const handleCloseEmpDetail = () => {
        setEmpId(null)
        setVisibleEmpDetail(false)
    }
    const getEmployeeInfo = () => {
        mainApi.getAllEmployee({}, (res) => {
            var tmp = {}
            res.forEach(elem => {
                if (elem.emp_id != "") {
                    tmp[elem.emp_id] = { firstname: elem.firstname, lastname: elem.lastname }
                }
            });
            setMasterEmp(tmp)
        }, (err) => { console.log(err) })
    }
    const getGraphSetting = () => {
        mainApi.getSetting({},
            (res) => {
                let new_ts = DEFAULT_THRESHOLD
                if (empLevel == 12 && res[0].ts_emp_emp != undefined) new_ts = res[0].ts_emp_emp / 100
                if (empLevel == 13 && res[0].ts_emp_emp_13 != undefined) new_ts = res[0].ts_emp_emp_13 / 100
                if (empLevel == 23 && res[0].ts_emp_emp_23 != undefined) new_ts = res[0].ts_emp_emp_23 / 100

                setThreshold(new_ts)
                if (mdata) setData(mdata.filter(elem => elem.rate > new_ts))

            }, (err) => { console.log(err) })
    }
    useEffect(() => {
        checkAccess()
        getEmployeeInfo()
        getGraphSetting()
    }, [])

    useEffect(() => {
        if (masterEmp) {
            getGraphSetting()
        }
    }, [toggleReload])

    useEffect(() => {
        if (masterEmp) {
            graphApi.getEmpEmp(empLevel,
                (res) => {
                    let master_emp_1 = {}
                    res[0].emp_1.map(elem => master_emp_1[elem._id] = elem.total)
                    let master_emp_2 = {}
                    res[0].emp_2.map(elem => master_emp_2[elem._id] = elem.total)
                    let master_emp_3 = {}
                    res[0].emp_3.map(elem => master_emp_3[elem._id] = elem.total)

                    let tmp = []
                    res[0].pair.forEach(elem => {
                        let e1 = elem._id?.split("|")[0]
                        let e2 = elem._id?.split("|")[1]
                        let e1_total = empLevel == 12 || empLevel == 13 ? master_emp_1[e1] : master_emp_2[e1]
                        let e2_total = empLevel == 12 ? master_emp_2[e2] : master_emp_3[e2]
                        let rate = elem.total / Math.min(e1_total, e2_total)
                        if (masterEmp && e1 in masterEmp && e2 in masterEmp) {
                            tmp.push({
                                e1: e1,
                                e2: e2,
                                e1_display: masterEmp[e1].firstname,
                                e2_display: masterEmp[e2].firstname,
                                freq: elem.total,
                                e1_total: e1_total,
                                e2_total: e2_total,
                                rate: rate
                            })
                        }
                    });

                    setMdata(tmp)
                    setData(tmp.filter(elem => elem.rate > threshold))
                },
                (err) => { console.log(err) }
            )
        }
    }, [masterEmp, empLevel])

    return (
        <>
            {data && <div className="content" style={{ maxWidth: '100%' }}>
                <div className="flex-row-box">
                    <div className="title">
                        กราฟแสดงความสัมพันธ์ระหว่างพนักงาน
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "5px" }}>
                            <MyTextField select fullWidth defaultValue={empLevel} onChange={(e) => setEmpLevel(e.target.value)}>
                                <MenuItem key={1} value={12}>พนักงานเครื่องชั่ง - ประเมินเกรดเบื้องต้น</MenuItem>
                                <MenuItem key={2} value={13}>พนักงานเครื่องชั่ง - ประเมินเกรด</MenuItem>
                                <MenuItem key={3} value={23}>พนักงานประเมินเกรดเบื้องต้น - ประเมินเกรด</MenuItem>
                            </MyTextField>
                        </div>
                        <Button
                            variant="outlined"
                            onClick={() => setVisible(true)}
                            startIcon={<img alt="Setting" src={SettingIcon} width="16px" />}
                        >ตั้งค่า</Button>
                    </div>
                </div>
                <div className="graph-box">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={networkOption(`Graph${empLevel}`, data)}
                    />
                </div>
                <div className="flex-row-box" style={{ marginBottom: "15px" }}>
                    <div className="title">
                        รายการพนักงานกับพนักงาน
                    </div>
                </div>
                {data?.length == 0 ? "ไม่พบข้อมูล" : <div>
                    <MaterialTable
                        title={false}
                        columns={columns}
                        data={data}
                        options={{
                            search: false,
                            showTitle: false,
                            toolbar: false,
                            headerStyle: { fontFamily: "Roboto, Noto Sans Thai, sans-serif", fontSize: "1rem" },
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50]
                        }}
                    />
                </div>}
                <Modal open={visible} onClose={handleClose}><GraphSetting handleClose={handleClose} /></Modal>
                <Modal open={visibleEmpDetail} onClose={handleCloseEmpDetail}><EmployeeDetail id={EmpId} /></Modal>

            </div>}
        </>
    )
}