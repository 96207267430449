import axios from "axios";

const BASE_URL = "/api";
// const BASE_URL = "http://localhost:5000";

const publicApi = axios.create({
    baseURL: BASE_URL,
    headers: {
        "content-Type": "application/json",
    },
    credentials: "same-origin",
});

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        "content-Type": "application/json",
        "Authorization": sessionStorage.getItem("access_token")
    },
    credentials: "same-origin",
});

api.defaults.withCredentials = false;

export const authApi = {
    login: (payload, callback, onRejected) => {
        publicApi
            .post("/auth/login", payload)
            .then(({ data }) => {
                sessionStorage.setItem("access_token", `Bearer ${data.access_token}`)
                callback("success");
            })
            .catch((err) => onRejected(err));
    },
    logout: (callback, onRejected) => {
        api
            .get("/auth/logout", {})
            .then(({ data }) => {
                callback("success");
            })
            .catch((err) => onRejected(err));
    },
};

export const mainApi = {
    getAllReceipt: (payload, callback, onRejected) => {
        api
            .post("/receipt/getAllReceipt", payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getTruckDetail: (id, callback, onRejected) => {
        api
            .get("/receipt/getTruckDetail/" + id)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getAllEmployee: (payload, callback, onRejected) => {
        api
            .post("/employee/getAllEmployee/", payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getEmployeeDetail: (id, callback, onRejected) => {
        api
            .get("/employee/getOneEmployee/" + id)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getMasterFilter: (callback, onRejected) => {
        api
            .get("/receipt/getMaster")
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getSetting: (payload, callback, onRejected) => {
        api
            .post("/setting/getAllSetting", payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    updateSetting: (id, payload, callback, onRejected) => {
        api
            .put("/setting/updateSetting/" + id, payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    updateSettingV2: (id, payload, callback, onRejected) => {
        api
            .post("/setting/updateSettingV2/" + id, payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getTotalMaster: (callback, onRejected) => {
        api
            .post("/master/getAllMaster")
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
};
export const graphApi = {
    getTruckEmp: (payload, callback, onRejected) => {
        api
            .post("/truckemp/getAllTruckEmp", payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getTruckGroup: (payload, callback, onRejected) => {
        api
            .post("/truckgroup/getAllTruckGroup", payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getFormular: (payload, callback, onRejected) => {
        api
            .post("/formular/getFormular", payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    updateFormular: (id, payload, callback, onRejected) => {
        api
            .put("/formular/updateFormular/" + id, payload)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },
    getEmpEmp: (lv, callback, onRejected) => {
        api
            .get(`/receipt/getEmpEmp/${lv}`)
            .then(({ data }) => callback(data))
            .catch((err) => onRejected(err));
    },

};