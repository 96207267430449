import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import TruckIcon from '../assets/img/icon_truck.png'
import EmployeeIcon from '../assets/img/icon_emp.png'
import { redirect } from "../services/helper";
import { graphApi } from "../services/api";

const sideMenu = [
    { label: 'Employee - Truck', path: "/graph/truck-emp", icon_default: EmployeeIcon },
    { label: 'Truck Group', path: "/graph/truck-group", icon_default: TruckIcon },
    { label: 'Employee - Employee', path: "/graph/emp-emp", icon_default: EmployeeIcon }]

export default function SideNavBar() {
    const [formular, setFormular] = useState(null)
    useEffect(() => {
        graphApi.getFormular({},
            (res) => {
                sessionStorage.setItem("fx", JSON.stringify(res))
                setFormular(res)
            },
            (err) => console.log(err)
        )
    }, [])

    return (
        <div className="side-bar">
            {window.location.pathname.includes("/graph") && <List style={{ width: "100%" }}>
                {sideMenu.map((item, index) => (
                    <ListItem
                        button
                        key={item}
                        onClick={(e) => { redirect(item.path) }}
                        selected={window.location.pathname == item.path}
                        style={{ height: "40px" }}
                    >
                        <ListItemIcon>{<img src={item.icon_default} width="16px" />}</ListItemIcon>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>}
            {window.location.pathname.includes("/home") && <div style={{ width: "260px", height: "300px", marginTop: "calc(50vh)" }}>
                <div style={{ fontSize: "1rem", lineHeight: "3.5rem" }}>สูตรการคำนวณ Fraud Probability</div>
                <div className="flex-row-box" style={{ fontSize: "0.8rem" }}>
                    <div className="detail-column" style={{ lineHeight: "2rem", marginLeft: "0.25rem" }}>
                        <div>{`สัดส่วนน้ำหนักชั่งเบาขึ้น-ลงผิดปกติ = ${formular?.ratio_outbound}`}</div>
                        <div>{`สัดส่วนน้ำหนักชั่งเบานิ่งผิดปกติ = ${formular?.ratio_inbound}`}</div>
                        <div>{`สัดส่วนน้ำหนักระหว่างเครื่องชั่ง = ${formular?.ratio_machine}`}</div>
                        <div>{`สัดส่วนการเข้าเฉพาะคลังใดคลังหนึ่ง = ${formular?.ratio_same_plant}`}</div>
                        <div>{`สัดส่วนพนักงานเครื่องชั่ง = ${formular?.ratio_emp_1}`}</div>
                        <div>{`สัดส่วนพนักงานประเมินเกรดเบื้องต้น = ${formular?.ratio_emp_2}`}</div>
                        <div>{`สัดส่วนพนักงานประเมินเกรด = ${formular?.ratio_emp_3}`}</div>
                    </div>
                </div>
            </div>}
        </div>
    )
}