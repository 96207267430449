import React, { useEffect, useState } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { mainApi } from "../services/api";
import { Divider } from "@material-ui/core"

const pieChartOption = (title, data) => {
    var opt = {
        chart: {
            type: 'pie',
            width: 380,
            height: 200
        },
        title: {
            text: ""
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.2f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                },
            }
        },
        series: [{
            name: title,
            data: data
        }]
    }
    return opt
}
export default function EmployeeDetail(props) {
    const [data, setData] = useState(null)

    useEffect(() => {
        mainApi.getEmployeeDetail(props.id,
            (res) => {
                if (res.length > 0) {
                    var tmp = {
                        emp_id: res[0].emp_id,
                        fname: res[0].firstname,
                        lname: res[0].lastname,
                        position: res[0].position,
                        status: res[0].status,
                        trucks: res[0].statistic[0].trucks.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        retails: res[0].statistic[0].retails.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        traders: res[0].statistic[0].traders.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        com_grades: res[0].statistic[0].com_grades.map(elem => elem._id != null && ({ name: elem._id, y: elem.count })),
                        total_suspected: res[0].statistic[0].overall[0].total_suspected,
                        total_fraud: res[0].statistic[0].overall[0].total_fraud,
                    }
                    setData(tmp)
                }
            },
            (err) => { console.log(err) }
        )
    }, [])

    return (
        <>
            {data && <div className="detail-modal">
                <div className="title">พนักงานรหัส {data?.emp_id}</div>
                <div className="section-title">ข้อมูลทั่วไป</div>
                <div className="flex-row-box" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column light-gray">
                        <div>รหัสพนักงาน</div>
                        <div>ชื่อ</div>
                        <div>นามสกุล</div>
                        <div>ตำแหน่ง</div>
                        <div>สถานะการทำงาน</div>
                    </div>
                    <div id="right-column" className="detail-column">
                        <div>{data?.emp_id || "-"}</div>
                        <div>{data?.fname || "-"}</div>
                        <div>{data?.lname || "-"}</div>
                        <div>{data?.position || "-"}</div>
                        <div>{data?.status || "-"}</div>
                    </div>
                </div>
                <Divider style={{ width: "100%", margin: "20px 0px 20px 0px" }} />
                <div className="section-title">ภาพรวม</div>
                <div className="flex-row-box light-gray" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column" style={{ width: "400px" }}>
                        <div>พนักงาน - รถขนส่ง</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Truck", data?.trucks)}
                        />
                    </div>
                    <div id="right-column" className="detail-column wide-column">
                        <div>พนักงาน - ร้านค้า</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Retail", data?.retails)}
                        />
                    </div>
                </div>
                <div className="flex-row-box light-gray" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column" style={{ width: "400px" }}>
                        <div>พนักงาน - ตัวแทน</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Trader", data?.traders)}
                        />
                    </div>
                    <div id="right-column" className="detail-column wide-column">
                        <div>พนักงาน - เกรดการค้า</div>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={pieChartOption("Com Grade", data?.com_grades)}
                        />
                    </div>
                </div>
                <Divider style={{ width: "100%", margin: "20px 0px 20px 0px" }} />
                <div className="section-title">ข้อมูลการ Fraud</div>
                <div className="flex-row-box" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column light-gray">
                        <div>จำนวนครั้งต้องสงสัย Fraud</div>
                        <div>จำนวนครั้ง Fraud</div>
                    </div>
                    <div id="right-column" className="detail-column">
                        <div>{data?.total_suspected}</div>
                        <div>{data?.total_fraud}</div>
                    </div>
                </div>
            </div>}
        </>
    )
}