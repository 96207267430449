import React from "react";
import { numberWithCommas } from "../services/helper";

export default function ScoreBoard(props){
    const x = numberWithCommas(props.value)
    return(
        <div className="score-board">
            <div style={{width: "100%", fontSize: "1.125rem", fontWeight: "400", textAlign: "left", marginBottom: "10px" }}>
                {props.title}
            </div>
            <div style={{width: "100%", fontSize: "1.5rem", fontWeight: "bold", textAlign: "right" }}>
                {x}
            </div>
        </div>
    )
}