import React, { useState, useEffect } from "react";
import { Button, MenuItem, Modal, TextField } from "@material-ui/core";
import MaterialTable from "@material-table/core";
import SettingIcon from '../assets/img/icon_setting.png'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import GraphSetting from '../components/graph_setting'
import { graphApi, mainApi } from "../services/api";
import { checkAccess } from "../services/helper";
import TruckDetail from "../components/truck_detail";
import EmployeeDetail from "../components/employee_detail";

require("highcharts/modules/networkgraph")(Highcharts);

function MyTextField(props) {
    return (
        <TextField
            variant="outlined" size="small" style={{ width: "250px", textAlign: "left" }}
            {...props}
        />
    );
}
const DEFAULT_THRESHOLD = 0.3

export default function TruckEmp() {
    const [data, setData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [visibleTruckDetail, setVisibleTruckDetail] = useState(false)
    const [TruckId, setTruckId] = useState(null)
    const [visibleEmpDetail, setVisibleEmpDetail] = useState(false)
    const [EmpId, setEmpId] = useState(null)
    const [EmpLevel, setEmpLevel] = useState(1)
    const [toggleReload, setToggleReload] = useState(false)

    const columns = [
        {
            title: "ทะเบียนรถ", field: "truck_id",
            render: row => <a onClick={() => handleShowTruckDetail(row.truck_id)}>{row.truck_id}</a>
        },
        {
            title: "รหัสพนักงาน", field: "employee_id",
            render: row => <a onClick={() => handleShowEmpDetail(row.employee_id)}>
                {row.employee.length > 0 ? row.employee[0].firstname + " " + row.employee[0].lastname : ""}
            </a>
        },
        { title: "จำนวนครั้งที่ทำรายการ", field: "total" },
        { title: "จำนวนครั้งที่พบกัน", field: "times" },
        {
            title: "สัดส่วนการพบกัน", field: "rate",
            render: row => <text>{parseFloat(row.rate).toFixed(2)}</text>
        },
    ];
    const networkOption = (title, data) => {
        let temp = data.map(elem => ({ from: elem.truck_id, to: elem.employee[0].firstname }))
        let truck_nodes = data.map(elem => ({ id: elem.truck_id, color: "orange", marker: { radius: 25 } }))
        let emp_nodes = data.map(elem => ({ id: elem.employee[0].firstname, color: "blue" }))

        var option = {
            chart: {
                type: 'networkgraph',
                height: 450
            },
            title: {
                text: ""
            },
            plotOptions: {
                networkgraph: {
                    layoutAlgorithm: {
                        enableSimulation: true,
                        linkLength: 20
                    }
                }
            },
            series: [{
                dataLabels: {
                    enabled: true,
                    linkFormat: ''
                },
                marker: {
                    radius: 18
                },
                data: temp,
                nodes: truck_nodes.concat(emp_nodes)
            }]
        }
        return option
    }
    const handleClose = () => {
        setVisible(false)
        setToggleReload(!toggleReload)
    }
    const handleShowTruckDetail = (id) => {
        setTruckId(id)
        setVisibleTruckDetail(true)
    }
    const handleCloseTruckDetail = () => {
        setTruckId(null)
        setVisibleTruckDetail(false)
    }
    const handleShowEmpDetail = (id) => {
        setEmpId(id)
        setVisibleEmpDetail(true)
    }
    const handleCloseEmpDetail = () => {
        setEmpId(null)
        setVisibleEmpDetail(false)
    }
    useEffect(() => {
        checkAccess()
        var payload = {}

        mainApi.getSetting({}, (res) => {
            if (res?.length > 0) {
                let rate = DEFAULT_THRESHOLD
                if (EmpLevel == 1 && res[0].ts_truck_emp) rate = res[0].ts_truck_emp / 100
                if (EmpLevel == 2 && res[0].ts_truck_emp_2) rate = res[0].ts_truck_emp_2 / 100
                if (EmpLevel == 3 && res[0].ts_truck_emp_3) rate = res[0].ts_truck_emp_3 / 100
                payload = {
                    level: EmpLevel,
                    rate: { "$gte": rate }
                }
            }
            graphApi.getTruckEmp(payload,
                (res) => {
                    setData(res)
                },
                (err) => { console.log(err) }
            )
        }, (err) => { console.log(err) })
    }, [toggleReload, EmpLevel])

    return (
        <>
            {data && <div className="content" style={{ maxWidth: '100%' }}>
                <div className="flex-row-box">
                    <div className="title">
                        กราฟแสดงความสัมพันธ์ระหว่างรถขนส่งกับพนักงาน
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: "5px" }}>
                            <MyTextField select fullWidth defaultValue={EmpLevel} onChange={(e) => setEmpLevel(e.target.value)}>
                                <MenuItem key={1} value={1}>พนักงานเครื่องชั่ง</MenuItem>
                                <MenuItem key={2} value={2}>พนักงานประเมินเกรดเบื้องต้น</MenuItem>
                                <MenuItem key={3} value={3}>พนักงานประเมินเกรด</MenuItem>
                            </MyTextField>
                        </div>
                        <Button
                            variant="outlined"
                            onClick={() => setVisible(true)}
                            startIcon={<img alt="Setting" src={SettingIcon} width="16px" />}
                        >ตั้งค่า</Button>
                    </div>
                </div>
                <div className="graph-box">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={networkOption("Graph1", data)}
                    />
                </div>
                <div className="flex-row-box" style={{ marginBottom: "15px" }}>
                    <div className="title">
                        รายการรถขนส่งกับพนักงาน
                    </div>
                </div>
                {data?.length == 0 ? "ไม่พบข้อมูล" : <div>
                    <MaterialTable
                        title={false}
                        columns={columns}
                        data={data}
                        options={{
                            search: false,
                            showTitle: false,
                            toolbar: false,
                            headerStyle: { fontFamily: "Roboto, Noto Sans Thai, sans-serif", fontSize: "1rem" },
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50]
                        }}
                    />
                </div>}
                <Modal open={visible} onClose={handleClose}><GraphSetting handleClose={handleClose} /></Modal>
                <Modal open={visibleTruckDetail} onClose={handleCloseTruckDetail}><TruckDetail id={TruckId} /></Modal>
                <Modal open={visibleEmpDetail} onClose={handleCloseEmpDetail}><EmployeeDetail id={EmpId} /></Modal>

            </div>}
        </>
    )
}