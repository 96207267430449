import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Divider, TextField } from "@material-ui/core"
import { mainApi } from "../services/api";

function MyTextField(props) {
    return (
        <TextField
            variant="outlined" type="number" size="small" style={{ width: "70px", marginBottom: "8px" }}
            {...props}
        />
    );
}
export default function GraphSetting(props) {
    const [data, setData] = useState(null)
    const [flag, setFlag] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        var payload = {
            date_from: data.date_from,
            date_end: data.date_end,
            ts_outbound: parseFloat(data.ts_outbound),
            ts_inbound: parseFloat(data.ts_inbound),
            ts_sameplant: parseFloat(data.ts_sameplant),
            weight_diff: parseFloat(data.weight_diff),
            ts_diff: parseFloat(data.ts_diff),
            minimum: parseFloat(data.minimum),
            ts_truck_emp: parseFloat(data.ts_truck_emp),
            ts_truck_emp_2: parseFloat(data.ts_truck_emp_2),
            ts_truck_emp_3: parseFloat(data.ts_truck_emp_3),
            ts_truck_group: parseFloat(data.ts_truck_group),
            ts_emp_emp: parseFloat(data.ts_emp_emp),
            ts_emp_emp_13: parseFloat(data.ts_emp_emp_13),
            ts_emp_emp_23: parseFloat(data.ts_emp_emp_23),

        }
        setLoading(true)
        if (flag) {
            mainApi.updateSettingV2(data?._id, payload,
                () => {
                    setLoading(false)
                    props.handleClose()
                },
                (err) => console.log(err)
            )
        } else {
            mainApi.updateSetting(data?._id, payload,
                () => {
                    setLoading(false)
                    props.handleClose()
                },
                (err) => console.log(err)
            )
        }
        setFlag(false)
    }
    useEffect(() => {
        mainApi.getSetting({}, (res) => {
            if (res?.length > 0) {
                setData(res[0])
            }
        }, (err) => { console.log(err) })
    }, [])

    return (
        <>
            {data && !loading && <div className="setting-modal">
                <div className="title">การตั้งค่า</div>
                <div className="flex-row-box" >
                    <div id="left-setting-column" className="detail-column wide-column light-gray" style={{ width: "180px", lineHeight: "3rem" }}>
                        <div>ข้อมูลที่ทำการวิเคราะห์</div>
                    </div>
                    <div id="right-column" className="detail-column" style={{ width: "320px", marginLeft: "-110px", lineHeight: "3rem", display: "flex", justifyContent: "space-between" }}>
                        <MyTextField defaultValue={data?.date_from}
                            type="date"
                            style={{ width: "150px" }}
                            onChange={(e) => {
                                setFlag(true)
                                setData({ ...data, date_from: e.target.value })
                            }} />
                        -
                        <MyTextField defaultValue={data?.date_end}
                            type="date"
                            style={{ width: "150px" }}
                            onChange={(e) => {
                                setFlag(true)
                                setData({ ...data, date_end: e.target.value })
                            }} /></div>
                </div>
                <Divider style={{ width: "100%", margin: "10px 0px 20px 0px" }} />
                <div className="flex-row-box" >
                    <div id="left-setting-column" className="detail-column light-gray" style={{ width: "450px", lineHeight: "3rem" }}>
                        <div>ความแปรปรวนของน้ำหนักชั่งเบา (ขึ้น-ลงผิดปกติ)</div>
                        <div>ความแปรปรวนของน้ำหนักชั่งเบา (นิ่งผิดปกติ)</div>
                        <div>สัดส่วนการเข้าเฉพาะคลังใดคลังหนึ่ง</div>
                        <div>ค่าแตกต่างของน้ำหนักระหว่างเครื่องชั่ง</div>
                        <div>ความแตกต่างของน้ำหนักระหว่างเครื่องชั่งเกินเกณฑ์</div>
                        <div>จำนวนครั้งขั้นต่ำในการทำรายการ</div>
                    </div>
                    <div id="right-column" className="detail-column" style={{ width: "110px", lineHeight: "3rem" }}>
                        <div><MyTextField defaultValue={data?.ts_outbound}
                            onChange={(e) => {
                                setFlag(true)
                                setData({ ...data, ts_outbound: e.target.value })
                            }} /> S.D.</div>
                        <div><MyTextField defaultValue={data?.ts_inbound}
                            onChange={(e) => {
                                setFlag(true)
                                setData({ ...data, ts_inbound: e.target.value })
                            }} /> S.D.</div>
                        <div><MyTextField defaultValue={data?.ts_sameplant}
                            onChange={(e) => {
                                setFlag(true)
                                setData({ ...data, ts_sameplant: e.target.value })
                            }} /> %</div>
                        <div><MyTextField defaultValue={data?.weight_diff}
                            onChange={(e) => {
                                setFlag(true)
                                setData({ ...data, weight_diff: e.target.value })
                            }} /> KG</div>
                        <div><MyTextField defaultValue={data?.ts_diff}
                            onChange={(e) => {
                                setFlag(true)
                                setData({ ...data, ts_diff: e.target.value })
                            }} /> %</div>
                        <div><MyTextField defaultValue={data?.minimum}
                            onChange={(e) => {
                                setFlag(true)
                                setData({ ...data, minimum: e.target.value })
                            }} /> ครั้ง</div>
                    </div>
                </div>
                <Divider style={{ width: "100%", margin: "10px 0px 20px 0px" }} />
                <div className="flex-row-box" >
                    <div id="left-setting-column" className="detail-column light-gray" style={{ width: "450px", lineHeight: "3rem" }}>
                        <div>สัดส่วนการพบเจอระหว่างรถขนส่งกับพนักงานเครื่องชั่ง</div>
                        <div>สัดส่วนการพบเจอระหว่างรถขนส่งกับพนักงานประเมินเกรดเบื้องต้น</div>
                        <div>สัดส่วนการพบเจอระหว่างรถขนส่งกับพนักงานประเมินเกรด</div>
                        <div>สัดส่วนการพบเจอระหว่างกลุ่มของรถขนส่ง</div>
                        <div>สัดส่วนการพบเจอระหว่างพนักงานเครื่องชั่ง - ประเมินเกรดเบื้องต้น</div>
                        <div>สัดส่วนการพบเจอระหว่างพนักงานเครื่องชั่ง - ประเมินเกรด</div>
                        <div>สัดส่วนการพบเจอระหว่างพนักงานประเมินเกรดเบื้องต้น - ประเมินเกรด</div>
                    </div>
                    <div id="right-column" className="detail-column" style={{ width: "110px", lineHeight: "3rem" }}>
                        <div><MyTextField defaultValue={data?.ts_truck_emp} onChange={(e) => setData({ ...data, ts_truck_emp: e.target.value })} /> %</div>
                        <div><MyTextField defaultValue={data?.ts_truck_emp_2} onChange={(e) => setData({ ...data, ts_truck_emp_2: e.target.value })} /> %</div>
                        <div><MyTextField defaultValue={data?.ts_truck_emp_3} onChange={(e) => setData({ ...data, ts_truck_emp_3: e.target.value })} /> %</div>
                        <div><MyTextField defaultValue={data?.ts_truck_group} onChange={(e) => setData({ ...data, ts_truck_group: e.target.value })} /> %</div>
                        <div><MyTextField defaultValue={data?.ts_emp_emp} onChange={(e) => setData({ ...data, ts_emp_emp: e.target.value })} /> %</div>
                        <div><MyTextField defaultValue={data?.ts_emp_emp_13} onChange={(e) => setData({ ...data, ts_emp_emp_13: e.target.value })} /> %</div>
                        <div><MyTextField defaultValue={data?.ts_emp_emp_23} onChange={(e) => setData({ ...data, ts_emp_emp_23: e.target.value })} /> %</div>
                    </div>
                </div>
                <div className="flex-row-box" style={{ marginTop: "2rem" }}>
                    <Button variant="outlined" color="primary" onClick={props.handleClose}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
                </div>
            </div>}
            {data && loading && <div className="setting-modal">
                <div className="title">การตั้งค่า</div>
                <div style={{width: '100%', textAlign: 'center'}}><CircularProgress /></div>
            </div>}
        </>
    )
}