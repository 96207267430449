import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core"
import { renderEmployee } from "../services/helper";

export default function ReceiptDetail(props) {
    const [data, setData] = useState(null)

    useEffect(() => {
        let fx = JSON.parse(sessionStorage.getItem("fx"))
        let tmp = {
            ...props.id,
            fraud_fx: `(${fx.ratio_outbound} * น้ำหนักชั่งเบาขึ้น-ลงผิดปกติ) + (${fx.ratio_inbound} * น้ำหนักชั่งเบานิ่งผิดปกติ) + (${fx.ratio_machine} * น้ำหนักระหว่างเครื่องชั่ง) + (${fx.ratio_same_plant} * การเข้าเฉพาะคลังใดคลังหนึ่ง) + (${fx.ratio_emp_1} * พนักงานเครื่องชั่ง) + (${fx.ratio_emp_2} * พนักงานประเมินเกรดเบื้องต้น) + (${fx.ratio_emp_3} * พนักงานประเมินเกรด)`
        }
        setData(tmp)
    }, [])

    return (
        <>
            {data && <div className="detail-modal">
                <div className="title">รายการ {data?.wgt_year}-{data?.wgt_ticket}-{data?.plant_code}</div>
                <div className="section-title">ข้อมูลทั่วไป</div>
                <div className="flex-row-box" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column light-gray">
                        <div>ปี</div>
                        <div>รหัสรายการ</div>
                        <div>รหัสคลัง</div>
                        <div>ชื่อคลัง</div>
                        <div>เริ่มทำรายการ</div>
                        <div>สิ้นสุดทำรายการ</div>
                        <div>ทะเบียนรถ</div>
                        <div>รหัสร้านค้า</div>
                        <div>ชื่อร้านค้า</div>
                        <div>รหัสตัวแทน</div>
                        <div>ชื่อตัวแทน</div>
                    </div>
                    <div id="right-column" className="detail-column">
                        <div>{data?.wgt_year || "-"}</div>
                        <div>{data?.wgt_ticket || "-"}</div>
                        <div>{data?.plant_code || "-"}</div>
                        <div>{data?.plant_name || "-"}</div>
                        <div>{data?.wgt_date_1.replace("T", " ").replace(".000Z", "")}</div>
                        <div>{data?.wgt_date_2.replace("T", " ").replace(".000Z", "")}</div>
                        <div>{data?.truck_id || "-"}</div>
                        <div>{data?.ret_code || "-"}</div>
                        <div>{data?.ret_name || "-"}</div>
                        <div>{data?.trd_code || "-"}</div>
                        <div>{data?.trd_name || "-"}</div>
                    </div>
                </div>
                <Divider style={{ width: "100%", margin: "20px 0px 20px 0px" }} />
                <div className="section-title">พนักงาน</div>
                <div className="flex-row-box" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column light-gray">
                        <div>พนักงานชั่ง</div>
                        <div>พนักงานประเมินเกรดเบื้องต้น</div>
                        <div>พนักงานประเมินเกรด</div>
                    </div>
                    <div id="right-column" className="detail-column">
                        <div>
                            {data?.emp_id_1 ? renderEmployee(data?.emp_id_1, data?.employee_1).map((elem, index) =>
                                <a onClick={() => props.openEmpModal(elem?.emp_id)}>{index > 0 && ' | '}{elem?.emp_name || elem?.emp_id}</a>
                            ) : "-"}
                        </div>
                        <div>
                            {data?.emp_id_2 ? renderEmployee(data?.emp_id_2, data?.employee_2).map((elem, index) =>
                                <a onClick={() => props.openEmpModal(elem?.emp_id)}>{index > 0 && ' | '}{elem?.emp_name || elem?.emp_id}</a>
                            ) : "-"}
                        </div>
                        <div>
                            {data?.emp_id_3 ? renderEmployee(data?.emp_id_3, data?.employee_3).map((elem, index) =>
                                <a onClick={() => props.openEmpModal(elem?.emp_id)}>{index > 0 && ' | '}{elem?.emp_name || elem?.emp_id}</a>
                            ) : "-"}
                        </div>
                    </div>
                </div>
                <Divider style={{ width: "100%", margin: "20px 0px 20px 0px" }} />
                <div className="section-title">รายละเอียด</div>
                <div className="flex-row-box" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column light-gray">
                        <div>Production Grade</div>
                        <div>Commercial Grade</div>
                        <div>น้ำหนักเหล็กสุทธิ</div>
                        <div>น้ำหนักชั่งหนัก (Wgt 1)</div>
                        <div>น้ำหนักชั่งเบา (Wgt 2)</div>
                        <div>Flag Return</div>
                        <div>น้ำหนักส่งคืน</div>
                        <div>การตัดน้ำหนักสุทธิ</div>
                    </div>
                    <div id="right-column" className="detail-column">
                        <div>{data?.prd_grade_name || "-"}</div>
                        <div>{data?.com_grade_name || "-"}</div>
                        <div>{data?.wgt_out_net || "-"}</div>
                        <div>{data?.wgt_in_1 || "-"}</div>
                        <div>{data?.wgt_out_1 || "-"}</div>
                        <div>{data?.flag_return || "-"}</div>
                        <div>{data?.wgt_return || "-"}</div>
                        <div>{data?.wgt_cut_total || "-"}</div>
                    </div>
                </div>
                <Divider style={{ width: "100%", margin: "20px 0px 20px 0px" }} />
                <div className="section-title">คะแนนการประเมิน</div>
                <div className="flex-row-box" style={{ justifyContent: "start" }}>
                    <div id="left-column" className="detail-column light-gray">
                        <div style={{ fontWeight: "600" }}>Fraud Probability</div>
                        <div>น้ำหนักชั่งเบาขึ้น-ลงผิดปกติ</div>
                        <div>น้ำหนักชั่งเบานิ่งผิดปกติ</div>
                        <div>น้ำหนักระหว่างเครื่องชั่ง</div>
                        <div>การเข้าเฉพาะคลังใดคลังหนึ่ง</div>
                        <div>พนักงานเครื่องชั่ง</div>
                        <div>พนักงานประเมินเกรดเบื้องต้น</div>
                        <div>พนักงานประเมินเกรด</div>
                        <div>สูตรการคำนวณ</div>
                    </div>
                    <div id="right-column" className="detail-column" style={{ width: "500px" }}>
                        <div style={{ fontWeight: "600" }}>{(data?.fraud_prob * 100).toFixed(2)}%</div>
                        <div>{data?.sc_outbound.toFixed(3)} S.D.</div>
                        <div>{data?.sc_inbound.toFixed(3)} S.D.</div>
                        <div>{data?.sc_machine == 0 ? "-" : data?.sc_machine.toFixed(3)}</div>
                        <div>{data?.sc_sameplant == 0 ? "-" : data?.sc_sameplant.toFixed(3)}</div>
                        <div>{data?.total > 0 ? (data?.count_emp_1 / data?.total).toFixed(3) : "-"}</div>
                        <div>{data?.total > 0 ? (data?.count_emp_2 / data?.total).toFixed(3) : "-"}</div>
                        <div>{data?.total > 0 ? (data?.count_emp_3 / data?.total).toFixed(3) : "-"}</div>
                        <div>{data?.fraud_fx}</div>
                    </div>
                </div>
            </div>}
        </>
    )
}